import React from 'react';
import logo from './data/logo.png';
import signin_disabled from './data/signin_disabled.gif'
import styled from '@emotion/styled';
import { sign } from 'crypto';

const AppContainer = styled.div``;

const HeaderCss = styled.div`
  background: darkgray;
  background-image: linear-gradient(to right, white 0px, #DDD 200px, #777 100%);
  width: 100%;
  height: 100%;
`;

const SigninButton = styled.div`
  float: right;
  position: relative;
  top: 5px;
  left: -5px;
  border: 1px solid #757575;
  border-radius: 5px;
  height: 19px;
`;

const BodyCss = styled.div`
  color: navy;
  font-family: Tahoma;
  font-size: 12px;
  padding: 5px;
`;

const App = () => 
<AppContainer>
  <HeaderCss>
    <img src={logo} className="App-logo" alt="logo" style={{ position: 'relative', left: '10px', top: '5px' }} />
    <SigninButton><img src={signin_disabled} alt="sign in" /></SigninButton>
  </HeaderCss>
  <BodyCss>
    Please come back later! -- <a href='https://wiki.msnld.com/'>Wiki</a>
  </BodyCss>
</AppContainer>;

export default App;
